import React from 'react';
import PropTypes from 'prop-types';
import DPicker from 'react-datepicker';
import moment from 'moment';
import { Input } from '@chakra-ui/react';
import Constants from '../../Constants';

import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
  const {
    closeOnSelect,
    dateFormat,
    timeFormat,
    id,
    inputProps,
    onChange,
    value,
    timeConstraints,
    onBlur,
    placeholder,
    ...rest
  } = props;

  const dateFormatStr = dateFormat || Constants.DEFAULT_READ_DATE_FORMAT;

  const getDateObject = val => {
    if (!val) return null;

    if (moment.isMoment(val) && val.isValid()) {
      return val.toDate();
    }

    if (val instanceof Date && !isNaN(val.getTime())) {
      return val;
    }

    if (typeof val === 'string') {
      const parsed = moment(val, dateFormatStr, true);
      if (parsed.isValid()) {
        return parsed.toDate();
      }
    }

    return null;
  };

  const handleChange = date => {
    let val = date;
    if (!date || isNaN(date.getTime())) {
      if (typeof onChange === 'function') onChange(null);
      return;
    }

    if ((date && typeof date === 'string' && date.length > 9) || !isNaN(date.getTime())) {
      val = moment(date, dateFormatStr, true);
    }

    // eslint-disable-next-line no-underscore-dangle
    const formattedVal = val?._isValid ? val.format(typeof timeFormat === 'string' ? timeFormat : dateFormatStr) : date;

    if (typeof onChange === 'function') onChange(formattedVal);
  };

  return (
    <DPicker
      {...{
        id,
        closeOnSelect: closeOnSelect || true,
        dateFormat: 'yyyy-MM-dd',
        onBlur: onBlur && typeof onBlur === 'function' ? onBlur : () => true,
        placeholderText: placeholder || inputProps?.placeholder || 'Select Date',
        ...inputProps,
        ...rest,
      }}
      selected={getDateObject(value) || null}
      onChange={handleChange}
      customInput={<Input autoFocus={false} />}
    />
  );
}

DatePicker.propTypes = {
  closeOnSelect: PropTypes.bool,
  dateFormat: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.shape({
    borderColor: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  timeFormat: PropTypes.string,
  timeConstraints: PropTypes.shape({
    hours: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
};

export default DatePicker;
